import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getUser, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {KTIcon} from '../../../../_metronic/helpers'
import './forms.css'

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const {t} = useTranslation(['auth'])
  const [loading, setLoading] = useState(false)
  const [viewPassword, setViewPassword] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('form.email.invalid'))
      .max(50, t('form.email.max'))
      .required(t('form.email.required')),
    password: Yup.string()
      .min(8, t('form.password.min'))
      .max(16, t('form.password.max'))
      .required(t('form.password.required')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUser()
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(t('form.failed'))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-center'>
        <h1 className='text-dark fw-bolder mb-3'>{t('login.title')}</h1>
      </div>

      {/* Socials <Socials />*/}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='form-floating fv-row mt-11 mb-8'>
        <input
          id='email'
          placeholder={t('form.email.label')}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          autoComplete='off'
        />
        <label htmlFor='email' className='fs-6 fw-bold text-gray-500'>
          {t('form.email.label')}
        </label>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-3'>
        <div className='input-group'>
          <div className='form-floating'>
            <input
              id='password'
              placeholder={t('form.password.label')}
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              type={viewPassword ? 'text' : 'password'}
              autoComplete='off'
            />
            <label htmlFor='password' className='fs-6 fw-bold text-gray-500'>
              {t('form.password.label')}
            </label>
          </div>
          <button
            type='button'
            className='input-group-text py-0'
            onClick={() => setViewPassword(!viewPassword)}
            aria-label='show password'
          >
            <KTIcon iconName={viewPassword ? 'eye' : 'eye-slash'} className='fs-2x' />
          </button>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        <Link to='/auth/forgot-password' className='link-primary'>
          {t('link.forgot_password')}
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              {t('button.wait')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <span className='indicator-label'>{t('button.login')}</span>
          )}
        </button>
      </div>

      {/*<div className='text-gray-500 text-center fw-semibold fs-6'>
        {t('link.not_member')}{' '}
        <Link to='/auth/registration' className='link-primary'>
          {t('link.signup')}
        </Link>
      </div>*/}
    </form>
  )
}
