import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../../modules/auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

interface PictureProps {
  onSave: (file: File) => void
}

const FILE_SIZE = 720 * 720
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg']

const ProfilePicture = ({onSave}: PictureProps) => {
  const {t} = useTranslation(['account'], {keyPrefix: 'picture'})
  const {currentUser} = useAuth()
  const [previewFile, setPreviewFile] = useState<string | undefined>(undefined)

  const imageSchema = Yup.object().shape({
    image: Yup.mixed()
      .required(t('image.required'))
      .test(
        'fileSize',
        t('image.fileSize'),
        (value) => (value as File) && (value as File).size <= FILE_SIZE
      )
      .test(
        'fileType',
        t('image.fileType'),
        (value) => (value as File) && SUPPORTED_FORMATS.includes((value as File).type)
      ),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: {image: null},
    validationSchema: imageSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (value) => {
      if (value.image && typeof value.image === 'object') onSave(value.image)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files && event.currentTarget.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewFile(reader.result as string)
      }
      reader.readAsDataURL(file)
      formik.setFieldValue('image', file as File)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_picture'
        aria-expanded='true'
        aria-controls='kt_account_profile_picture'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{t('title')}</h3>
        </div>
      </div>

      <div id='kt_account_profile_picture' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <div className='col-lg-4'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-150px h-150px'
                    style={{
                      backgroundImage: `url(${toAbsoluteUrl(
                        previewFile
                          ? previewFile
                          : currentUser?.pic
                            ? currentUser.pic
                            : '/media/avatars/blank.png'
                      )})`,
                    }}
                  ></div>
                </div>
              </div>
              <div className='col-lg-8 d-flex'>
                <input
                  type='file'
                  placeholder={t('image.placeholder')}
                  className='mt-auto mb-2'
                  accept={SUPPORTED_FORMATS.join(',')}
                  onChange={handleFileChange}
                />
                {formik.errors.image && formik.touched.image && <div>{formik.errors.image}</div>}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={!formik.isValid}>
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProfilePicture
