import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {socket} from '../socket'
import {FaultInterface} from '../../pages/settings/components/SettingsInterface'
import {useAuth} from '../auth'

interface FaultContextType {
  faults: FaultInterface[]
  addFault: (insertedFault: FaultInterface) => Promise<void>
  updateFault: (updatedFault: FaultInterface) => Promise<void>
  removeFault: (id: number) => Promise<void>
}

const FaultContext = createContext<FaultContextType | undefined>(undefined)

export const useFaultContext = () => {
  const context = useContext(FaultContext)
  if (!context) {
    throw new Error('useFaultContext must be used within a FaultProvider')
  }
  return context
}

export function FaultContextProvider({children}) {
  const {currentUser} = useAuth()
  const [faults, setFaults] = useState<FaultInterface[]>([])

  useEffect(() => {
    fetchFaults()

    // Handle socket events
    socket.connect()

    socket.on('connect', () => {
      console.debug('faultContext connected to server', socket.id)
    })

    socket.on('disconnect', () => {
      console.debug('faultContext disconnected from server')
    })

    socket.on('fault-removed', (removedFault) => {
      setFaults((prevFaults) => prevFaults.filter((m) => m.id !== removedFault.id))
    })

    socket.on('fault-updated', (updatedFault) => {
      setFaults((prevFaults) =>
        prevFaults.map((m) => (m.id === updatedFault.id ? Object.assign(m, updatedFault) : m))
      )
    })

    socket.on('fault-inserted', (insertedFault) => {
      setFaults((prevFaults) => [...prevFaults, insertedFault])
    })

    return () => {
      socket.off('fault-removed')
      socket.off('fault-updated')
      socket.off('fault-inserted')
    }
  }, [])

  const fetchFaults = () => {
    axios
      .get('/api/fault/list')
      .then((response) => {
        setFaults(response.data)
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }
  const removeFault = async (id: number) => {
    return axios
      .delete('/api/fault/${id}')
      .then((response) => {
        console.log(response)

        setFaults((prevFaults) => prevFaults.filter((m) => m.id !== id))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateFault = async (updatedFault: FaultInterface) => {
    return axios
      .post(`/api/fault/${updatedFault.id}`, updatedFault)
      .then((response) => {
        console.log(response)

        setFaults((prevFaults) =>
          prevFaults.map((t) =>
            t.id === updatedFault.id
              ? Object.assign(t, updatedFault, {
                  updated_at: new Date(),
                  updated_by: currentUser ? currentUser.name : '',
                })
              : t
          )
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addFault = async (insertedFault: FaultInterface) => {
    return axios
      .put('/api/fault', insertedFault)
      .then((response) => {
        console.log(response)

        const {id_fault} = response.data
        setFaults((prevFaults) => {
          insertedFault.id = id_fault
          insertedFault.updated_at = new Date()
          insertedFault.updated_by = currentUser ? currentUser.name : ''
          return [...prevFaults, insertedFault]
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  return (
    <FaultContext.Provider value={{faults, addFault, updateFault, removeFault}}>
      {children}
    </FaultContext.Provider>
  )
}
