import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {useAuth} from '../auth'
import {socket} from '../socket'
import UserInterface from '../../pages/users/components/UserInterface'
import {TeamInterface, TeamUserInterface} from '../../pages/teams/components/TeamInterface'

interface UserContextType {
  users: UserInterface[]
  teams: TeamInterface[]
  addUser: (insertedUser: UserInterface) => Promise<void>
  updateUser: (updatedUser: UserInterface) => Promise<void>
  removeUser: (id: number) => Promise<void>
  addTeam: (insertedTeam: TeamInterface) => Promise<void>
  updateTeam: (updatedTeam: TeamInterface) => Promise<void>
  removeTeam: (id: number) => Promise<void>
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export function UserContextProvider({children}) {
  const {auth, currentUser} = useAuth()
  const [users, setUsers] = useState<UserInterface[]>([])
  const [teams, setTeams] = useState<TeamInterface[]>([])

  useEffect(() => {
    // Handle socket events
    socket.connect()

    socket.on('connect', () => {
      console.debug('userContext connected to server', socket.id)
    })

    socket.on('disconnect', () => {
      console.debug('userContext disconnected from server')
    })

    socket.on('user-removed', (removedUser) => {
      setUsers((prevUsers) => prevUsers.filter((m) => m.id !== removedUser.id))
      // Atualiza na lista de equipas
      setTeams((prevList) =>
        prevList.map((t) => {
          t.id_users = t.id_users?.filter((user) => Number(user) !== removedUser.id)
          return t
        })
      )
    })

    socket.on('user-updated', (updatedUser) => {
      setUsers((prevUsers) =>
        prevUsers.map((m) => (m.id === updatedUser.id ? Object.assign(m, updatedUser) : m))
      )
    })

    socket.on('user-inserted', (insertedUser) => {
      setUsers((prevUsers) => [...prevUsers, insertedUser])
    })

    socket.on('team-removed', (removedTeam) => {
      setTeams((prevTeams) => prevTeams.filter((m) => m.id !== removedTeam.id))

      setUsers((prevUsers) =>
        prevUsers.map((m) => {
          // Atualiza na lista de equipas
          m.teams = m.teams?.filter((team) => team.id !== removedTeam.id)
          return m
        })
      )
    })

    socket.on('team-updated', (updatedTeam) => {
      setTeams((prevTeams) =>
        prevTeams.map((m) => (m.id === updatedTeam.id ? Object.assign(m, updatedTeam) : m))
      )

      const id_users = updatedTeam.id_users?.map(Number) || []
      setUsersTeam(updatedTeam as TeamUserInterface, id_users)
    })

    socket.on('team-inserted', (insertedTeam) => {
      setTeams((prevList) => (prevList ? [...prevList, insertedTeam] : [insertedTeam]))

      const id_users = insertedTeam.id_users?.map(Number) || []
      setUsersTeam(insertedTeam as TeamUserInterface, id_users)
    })

    return () => {
      socket.off('user-removed')
      socket.off('user-updated')
      socket.off('user-inserted')
      socket.off('team-removed')
      socket.off('team-updated')
      socket.off('team-inserted')
    }
  }, [])

  const setUsersTeam = (updatedTeam: TeamUserInterface, id_users: number[]) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id && !id_users.includes(user.id)) return user

        // Adiciona à lista de equipas
        if (!Array.isArray(user.teams)) {
          user.teams = [updatedTeam]
          return user
        }

        // Adiciona à lista de equipas
        if (!user.teams.some((team) => team.id === updatedTeam.id)) {
          user.teams.push(updatedTeam)
          return user
        }

        // Atualiza na lista de equipas
        user.teams = user.teams.map((team) => (team.id === updatedTeam.id ? updatedTeam : team))

        return user
      })
    )
  }

  const fetchUsers = () => {
    axios
      .get('/api/user/list')
      .then((response) => {
        setUsers(response.data ?? [])
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const fetchTeams = () => {
    axios
      .get('/api/team/list')
      .then((response) => {
        setTeams(response.data ?? [])
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeTeam = async (id: number) => {
    return axios
      .delete(`/api/team/${id}`)
      .then(() => {
        setTeams((prevTeams) => prevTeams.filter((t) => t.id !== id))

        setUsers((prevUsers) =>
          prevUsers.map((m) => {
            // Atualiza na lista de equipas
            m.teams = m.teams?.filter((team) => team.id !== id)
            return m
          })
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateTeam = async (updatedTeam: TeamInterface) => {
    return axios
      .post(`/api/team/${updatedTeam.id}`, updatedTeam)
      .then((response) => {
        console.log(response)

        setTeams((prevTeams) =>
          prevTeams.map((t) => {
            if (t.id === updatedTeam.id) {
              updatedTeam.updated_at = new Date()
              updatedTeam.updated_by = currentUser ? currentUser.name : ''
              return updatedTeam
            } else return t
          })
        )

        const id_users = updatedTeam.id_users?.map(Number) || []
        setUsersTeam(updatedTeam as TeamUserInterface, id_users)
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addTeam = async (insertedTeam: TeamInterface) => {
    return axios
      .put('/api/team', insertedTeam)
      .then((response) => {
        console.log(response)

        const {id_team} = response.data
        setTeams((prevTeams) => {
          insertedTeam.id = id_team
          insertedTeam.updated_at = new Date()
          insertedTeam.updated_by = currentUser ? currentUser.name : ''
          return prevTeams ? [...prevTeams, insertedTeam] : [insertedTeam]
        })

        const id_users = insertedTeam.id_users?.map(Number) || []
        setUsersTeam(insertedTeam as TeamUserInterface, id_users)
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const removeUser = async (id: number) => {
    return axios
      .delete(`/api/user/${id}`)
      .then(() => {
        setUsers((prevUsers) => prevUsers.filter((m) => m.id !== id))

        setTeams((prevTeams) =>
          prevTeams.map((t) => {
            // Atualiza na lista de equipas
            t.id_users = t.id_users?.filter((user) => Number(user) !== id)
            return t
          })
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateUser = async (updatedUser: UserInterface) => {
    return axios
      .post(`/api/user/${updatedUser.id}`, updatedUser)
      .then((response) => {
        console.log(response)

        setUsers((prevUsers) =>
          prevUsers.map((m) => {
            if (m.id === updatedUser.id) {
              updatedUser.updated_at = new Date()
              updatedUser.updated_by = currentUser ? currentUser.name : ''
              delete updatedUser.password
              delete updatedUser.pin
              return updatedUser
            } else return m
          })
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addUser = async (insertedUser: UserInterface) => {
    return axios
      .put('/api/user', insertedUser)
      .then((response) => {
        console.log(response)

        const {id_user} = response.data
        setUsers((prevUsers) => {
          insertedUser.id = id_user
          insertedUser.updated_at = new Date()
          insertedUser.updated_by = currentUser ? currentUser.name : ''
          delete insertedUser.password
          delete insertedUser.pin
          return [...prevUsers, insertedUser]
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  useEffect(() => {
    if (auth) {
      fetchUsers()
      fetchTeams()
    }
  }, [auth])

  return (
    <UserContext.Provider
      value={{users, teams, addUser, updateUser, removeUser, addTeam, updateTeam, removeTeam}}
    >
      {children}
    </UserContext.Provider>
  )
}
