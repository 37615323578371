import {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {Modal, Button} from 'react-bootstrap'
import * as Yup from 'yup' // Import Yup for validation
import {useFormik} from 'formik' // Import useFormik hook from formik library
import {KioskInterface} from './SettingsInterface'
import SwitchButton from '../../../GenericComponents/SwitchButton'
import MultiSelect from '../../../GenericComponents/MultiSelect'
import {useMachineContext} from '../../../modules/machines'
import {useUserContext} from '../../../modules/users'

interface ModalProps {
  show: boolean
  setShow: (state: boolean) => void
  kiosk: KioskInterface | null
  onSave: (updatedKiosk: KioskInterface) => void
  onRemove: ((removedKiosk: KioskInterface) => void) | null
}

const emptyKiosk: KioskInterface = {
  id: null,
  username: '',
  password: undefined,
  id_users: undefined,
  updated_by: undefined,
  updated_at: undefined,
}

interface Option {
  value: string
  label: string
}

const KioskModal = ({show, setShow, kiosk, onSave, onRemove}: ModalProps) => {
  const {t} = useTranslation(['settings'], {keyPrefix: 'kiosk.form'})
  const {machines} = useMachineContext()
  const {users} = useUserContext()

  const [manualPassword, setManualPassword] = useState(false)
  const [selectedMachines, setSelectedMachines] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [machineOptions, setMachineOptions] = useState<Option[]>([])
  const [userOptions, setUserOptions] = useState<Option[]>([])

  // Define the validation schema for the KioskInterface
  const kioskSchema = Yup.object().shape({
    username: Yup.string().required(t('username.required')),
    password: manualPassword
      ? Yup.string().required(t('password.required'))
      : Yup.string().nullable(),
    id_machines: Yup.array(Yup.string())
      .min(1, t('machines.required'))
      .required(t('machines.required')),
    id_users: Yup.array(Yup.string()).min(1, t('users.required')).required(t('users.required')),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: kiosk || emptyKiosk,
    validationSchema: kioskSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
      resetForm()
    },
  })

  const handleRemove = () => {
    if (onRemove && kiosk) onRemove(kiosk)
    resetForm()
  }

  const resetForm = () => {
    setManualPassword(false)
    setShow(false)
    formik.resetForm()
  }

  useEffect(() => {
    formik.setFieldValue('id_machines', selectedMachines)
  }, [selectedMachines])

  useEffect(() => {
    formik.setFieldValue('id_users', selectedUsers)
  }, [selectedUsers])

  useEffect(() => {
    formik.validateField('password')
  }, [manualPassword])

  useEffect(() => {
    if (show) {
      setMachineOptions(machines.map((m) => ({value: m.id ? m.id.toString() : '', label: m.name})))
      setSelectedMachines(kiosk && kiosk.id_machines ? kiosk.id_machines : [])
    }
  }, [show, kiosk, machines])

  useEffect(() => {
    if (show) {
      setUserOptions(users.map((u) => ({value: u.id ? u.id.toString() : '', label: u.name})))
      setSelectedUsers(kiosk && kiosk.id_users ? kiosk.id_users : [])
    }
  }, [show, kiosk, users])

  return (
    <Modal show={show} onHide={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>{t(`title.${kiosk ? 'edit' : 'add'}`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className='mb-10'>
            <label className='required form-label'>{t('username.label')}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              maxLength={255}
              placeholder={t('username.placeholder')}
              {...formik.getFieldProps('username')}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className='text-danger'>{formik.errors.username}</div>
            ) : null}
          </div>

          <div className='mb-10'>
            <label className='required form-label'>{t('machines.label')}</label>
            <MultiSelect
              options={machineOptions}
              selectedOptions={selectedMachines}
              setSelectedOptions={setSelectedMachines}
              allOption={t('machines.all')}
              noneOption={t('machines.none')}
            >
              {t('machines.placeholder')}
            </MultiSelect>
            {formik.touched.id_machines && formik.errors.id_machines ? (
              <div className='text-danger'>{formik.errors.id_machines}</div>
            ) : null}
          </div>

          <div className='mb-10'>
            <label className='required form-label'>{t('users.label')}</label>
            <MultiSelect
              options={userOptions}
              selectedOptions={selectedUsers}
              setSelectedOptions={setSelectedUsers}
              allOption={t('users.all')}
              noneOption={t('users.none')}
            >
              {t('users.placeholder')}
            </MultiSelect>
            {formik.touched.id_users && formik.errors.id_users ? (
              <div className='text-danger'>{formik.errors.id_users}</div>
            ) : null}
          </div>

          <SwitchButton value={manualPassword} onChange={setManualPassword}>
            {t('password.label')}
          </SwitchButton>

          {manualPassword ? (
            <div className='mb-10 mt-5'>
              <input
                type='password'
                className='form-control form-control-solid'
                placeholder={t('password.placeholder')}
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className='text-danger'>{formik.errors.password}</div>
              ) : null}
            </div>
          ) : null}
        </form>
      </Modal.Body>

      <Modal.Footer>
        {onRemove && kiosk ? (
          <Button variant='danger' onClick={handleRemove}>
            {t('button.delete')}
          </Button>
        ) : (
          <Button variant='secondary' onClick={resetForm}>
            {t('button.cancel')}
          </Button>
        )}
        <Button
          type='submit'
          variant='primary'
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default KioskModal
