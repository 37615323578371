import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import Event from '../../GenericComponents/EventCalendar/EventInterface'
import {Task, Activity} from '../../pages/maintenance/components/TaskInterface'
import {sortTasks} from './components/Functions'
import {getNextActions} from '../templates/components/Functions'
import {useMachineContext} from '../machines'
import {useTemplateContext} from '../templates'
import {useAuth} from '../auth'
import {socket} from '../socket'

interface TaskContextType {
  tasks: Task[]
  addTask: (e: Event) => Promise<void>
  updateTask: (e: Event) => Promise<void>
  setTaskAction: (task: Task, activity: Activity) => Promise<void>
  removeTask: (id: number) => Promise<void>
}

const TaskContext = createContext<TaskContextType | undefined>(undefined)

export const useTaskContext = () => {
  const context = useContext(TaskContext)
  if (!context) {
    throw new Error('useTaskContext must be used within a TaskProvider')
  }
  return context
}

export function TaskContextProvider({children}) {
  const [tasks, setTasks] = useState<Task[]>([])
  const {machines, setMachines} = useMachineContext()
  const {templates} = useTemplateContext()
  const {currentUser} = useAuth()

  useEffect(() => {
    fetchTasks()

    // Handle socket events
    socket.connect()

    socket.on('connect', () => {
      console.debug('taskContext connected to server', socket.id)
    })

    socket.on('disconnect', () => {
      console.debug('taskContext disconnected from server')
    })

    socket.on('task-removed', (removedTask) => {
      setTasks((prevTasks) => prevTasks.filter((m) => m.id !== removedTask.id))
    })

    socket.on('task-updated', (updatedTask) => {
      setTasks((prevTasks) =>
        prevTasks.map((m) =>
          m.id === updatedTask.id ? Object.assign(m, updatedTask, {updated_at: new Date()}) : m
        )
      )
    })

    socket.on('task-inserted', (insertedTask) => {
      setTasks((prevTasks) => [...prevTasks, insertedTask])
    })

    return () => {
      socket.off('task-removed')
      socket.off('task-updated')
      socket.off('task-inserted')
    }
  }, [])

  useEffect(() => {
    if (socket.connected) {
      socket.on('task-activity', (taskActivity) => {
        setTasks((prevTasks) =>
          prevTasks.map((m) => {
            if (m.id === taskActivity.id_task) {
              // Adiciona nova atividade
              m.activity.push(Object.assign(taskActivity, {created_at: new Date()}))

              // Verifica fim de tarefa e atualiza data de fim
              if (hasTaskEnded(m)) {
                m.ended_at = new Date()
                m.ended_by = taskActivity.created_by
              }
            }
            return m
          })
        )
      })
    }

    return () => {
      socket.off('task-activity')
    }
  }, [socket.connected, templates])

  const fetchTasks = () => {
    axios
      .get('/api/task/list')
      .then((response) => {
        setTasks(response.data)
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeTask = async (id: number) => {
    return axios
      .delete(`/api/task/${id}`)
      .then((response) => {
        console.log(response)

        setTasks((prevTasks) => prevTasks.filter((m) => m.id !== id))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateTask = async (e: Event) => {
    const task = {
      ...e,
      id: Number(e.id),
      name: e.title,
      scheduled_for: e.start,
      scheduled_end: e.end,
    } as unknown as Task

    return axios
      .post(`/api/task/${task.id}`, task)
      .then((response) => {
        console.log(response)

        setTasks((prevTasks) =>
          prevTasks.map((t) => (t.id === task.id ? Object.assign(t, task) : t))
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addTask = async (e: Event) => {
    let task = {
      ...e,
      name: e.title,
      scheduled_for: e.start,
      scheduled_end: e.end,
      created_at: new Date(),
      created_by: currentUser?.id,
      activity: [],
    } as unknown as Task

    return axios
      .put('/api/task', task)
      .then((response) => {
        console.log(response)

        const {id_task} = response.data
        setTasks((prevTasks) => {
          task.id = id_task
          return [...prevTasks, task]
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const setTaskAction = async (task: Task, activity: Activity) => {
    return axios
      .post(`/api/task/${task.id}/${activity.type}`, activity)
      .then((response) => {
        if (currentUser && response.data.id) {
          const now = new Date()

          task.activity.push({
            id: response.data.id,
            type: activity.type,
            text: activity.text,
            created_at: now,
            created_by: currentUser.id,
          })

          // Verifica fim de tarefa e atualiza data de fim
          if (hasTaskEnded(task)) {
            task.ended_at = now
            task.ended_by = currentUser.id
          }
        }

        setTasks((prevTasks) => prevTasks.map((t) => (t.id === task.id ? task : t)))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const setMachineTasks = () => {
    setMachines((prevMachines) => {
      return prevMachines.map((m) => {
        const machineTasks = tasks.filter((t: Task) => t.id_machine === m.id),
          pending = machineTasks.filter((t: Task) => {
            if (!t.activity) return false
            const list = t.activity.slice().reverse()
            const start = list.find((a) => a.type === 'start') ?? null,
              end = list.find((a) => a.type === 'end') ?? null
            return (start && !end) || (start && end && start.created_at > end.created_at)
          }),
          next = machineTasks.filter(
            (t: Task) => !t.activity || !t.activity.find((a) => a.type === 'start')
          )

        // Se não houver tarefas pendentes
        m.operational = !pending.length

        // Tarefa atual
        m.current_task = pending.sort(sortTasks)[0]

        // Próxima tarefa
        m.next_task = next.sort(sortTasks)[0]

        return m
      })
    })
  }

  // Verificação de tarefa concluída
  const hasTaskEnded = (task: Task) => {
    const template = templates.find((t) => t.id === task.id_template)
    if (!template) return false

    const nextActions = getNextActions(template, task)
    if (!nextActions.length) return true

    return false
  }

  useEffect(() => {
    if (machines.length) setMachineTasks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, machines.length])

  return (
    <TaskContext.Provider value={{tasks, addTask, updateTask, setTaskAction, removeTask}}>
      {children}
    </TaskContext.Provider>
  )
}
