import {useTranslation} from 'react-i18next'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {UserModel} from '../../../modules/auth'

interface ProfileProps {
  user: UserModel
  onSave: (updatedUser: UserModel) => void
}

const ProfileDetails = ({user, onSave}: ProfileProps) => {
  const {t} = useTranslation(['account'], {keyPrefix: 'details'})

  const phoneReg = /^9[0-9]{8}|(00[0-9]{8,18})$/
  const profileSchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    email: Yup.string().email(t('email.invalid')).required(t('email.required')),
    phone: Yup.string().matches(phoneReg, t('phone.invalid')).default(null).nullable(),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: user,
    validationSchema: profileSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{t('title')}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                {t('name.label')}
              </label>

              <div className='col-lg-8  fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder={t('name.placeholder')}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>{t('email.label')}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={t('email.placeholder')}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>{t('phone.label')}</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  inputMode='numeric'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={t('phone.placeholder')}
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={!formik.isValid}>
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProfileDetails
