import {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {KTIcon} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'

const Maintenance: FC = () => {
  const {t} = useTranslation(['home'], {keyPrefix: 'maintenance'})
  const [resume, setResume] = useState({avg: 0, today: 0, total: 0})

  const getResume = () => {
    axios.get('/api/task/resume').then((response) => {
      setResume(response.data)
    })
  }

  useEffect(getResume, [])

  return (
    <div className='card mb-5'>
      <div className='card-header border-0'>
        <h3 className='card-title'>{t('title')}</h3>
        <div className='card-toolbar gap-3'>
          <Link to='/maintenance/history'>
            <KTIcon iconName='arrow-up-right' className='fs-1' />
          </Link>
        </div>
      </div>

      <div className='px-8 pb-8 collapse show'>
        <div className='card position-relative'>
          <div className='row g-0 pb-3'>
            <div className='col py-6 me-3'>
              <div className='fs-6 text-gray-400'>{t('average')}</div>
              <div className='fs-2 fw-bold text-gray-800'>{resume.avg ?? 0}</div>
            </div>

            <div className='col py-6 mx-3'>
              <div className='fs-6 text-gray-400'>{t('today')}</div>
              <div className='fs-2 fw-bold text-gray-800'>{resume.today ?? 0}</div>
            </div>

            <div className='col py-6 ms-3'>
              <div className='fs-6 text-gray-400'>{t('alltime')}</div>
              <div className='fs-2 fw-bold text-gray-800'>{resume.total}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Maintenance
